
import { switchLanguage } from '@/modules/app/app'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import i18n, { languages } from '@/i18n'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { defineComponent, shallowRef } from 'vue'
import { useRouter } from 'vue-router'
import Button from '@/components/Button.vue'

export default defineComponent({
  name: 'Locale',
  components: { Icon, Pic, PageWithHeader, Button },
  setup () {
    const router = useRouter()
    const lang = shallowRef(i18n.locale.value)

    const changeLanguage = (clang: string) => {
      lang.value = clang
    }
    const saveClick = () => {
      console.log(lang)
      switchLanguage(lang.value).then(router.back)
    }
    return {
      lang,
      locales: languages,
      changeLanguage,
      saveClick,
    }
  },
})
